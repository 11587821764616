//
// Portal entry file
// --------------------------------------------------

// Webpack entries
// Styles
import '../css/portal.scss';
// Images
import './webpack/require-img';
// SVGs
import './webpack/require-svg';

// SVG <use> polyfill
import 'svgxuse';

// Manually load some polyfills based on `useBuiltIns: usage`
Array.from;
Object.assign;

// JS module imports
import './modules/fontfaceobserver';
import './modules/object-fit-images';
import './modules/reveal-animations';
import './modules/scrollbar';
import './modules/smooth-scroll';
import './modules/gallery';
import './modules/slider';
import './modules/cookieconsent';
import './modules/cards';
import Topbar from './modules/topbar';
import Overlays from './modules/overlays';
import Inputs from './modules/inputs';
import Contactform from './modules/contactform';
import Share from './modules/share';
import Map from './modules/map';
import Panels from './modules/panels';

new Topbar();
new Overlays();
new Map();
new Inputs();
new Contactform();
new Share();
new Panels();

